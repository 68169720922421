<template>
  <section class="pb-8" id="contact">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row justify="center">
            <v-col cols="12" sm="5">
              <h1 class="font-weight-light display-1">Contactanos</h1>
              <h3 class="font-weight-light mt-3">
                En Veccit, estamos aquí para atender todas tus consultas y necesidades. Si tienes preguntas, comentarios o estás interesado en nuestros servicios de desarrollo de software web, no dudes en contactarnos. Estamos comprometidos en brindarte la mejor atención y soluciones a medida para tu proyecto.
              </h3>
              <h3 class="font-weight-light mt-3">
                Puedes comunicarte con nosotros a través de los siguientes medios:
              </h3>
              <h3 class="font-weight-light mt-3">
                Telefone: +58 412 1616871
              </h3>
              <h3 class="font-weight-light">
                Email: eemchjw@gmail.com
              </h3>
            </v-col>
            <v-col cols="12" sm="7">
              <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                <v-text-field
                     label="Usuario"
    required
    readonly
    value="Master"
                ></v-text-field>

                <v-text-field
                    
                    label="Password"
                    required
                    readonly
                    value="Master123"
                     
                ></v-text-field>

               

                <v-btn
                    
                    color="primary"
                    :dark="valid"
                    rounded
                    block
                    class="mt-3"
                    
                    @click="redirectToPortfolio"
                >
                  Accede a nuestra App Administrativa
                </v-btn>
                
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div class="svg-border-waves text-white">
      <v-img src="~@/assets/img/borderWavesBlue.svg"/>
    </div>
    <v-snackbar
        v-model="snackbar.enabled"
        timeout="3000"
        right
        top
        :color="snackbar.color"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar.enabled = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>

<style scoped>
#contact {
  background-color: #f4f7f5;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

</style>

<script>
// import {db} from '@/main'

export default {
  data: () => ({
    icons: ["fa-facebook", "fa-twitter", "fa-linkedin", "fa-instagram"],
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "El nombre es un campo obligatorio",
      (v) => (v && v.length >= 6) || "El nombre debe tener más de 6 caracteres",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "Email es un campo obligatorio",
      (v) => /.+@.+\..+/.test(v) || "El email debe ser válido",
    ],
    textArea: "",
    textAreaRules: [
      (v) => !!v || "Su mensaje no puede estar vacio",
      (v) => (v && v.length >= 10) || "Mínimo de 10 caracteres",
    ],
    lazy: false,
    snackbar: {
      enabled: false,
      text: '',
      color: ''
    }
  }),
  methods: {
    redirectToPortfolio() {
      window.open('https://sistemaonline.co/WebDeveloper/app/', '_blank');
    }
  }
};
 





</script>

